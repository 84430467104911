<template>
  <div class="relative">
    <div v-if="codeExpired" class="absolute inset-0">
      <div
        @click="generateToken(true)"
        class="flex h-full w-full cursor-pointer flex-col items-center justify-center bg-gray-700 bg-opacity-95 text-gray-300 hover:bg-gray-600 hover:bg-opacity-95"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="m-2 h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
        <div class="flex text-sm font-semibold text-gray-400">Click to reload QR code</div>
      </div>
    </div>
    <div class="flex space-x-2">
      <div class="my-px rounded border border-black">
        <VueQrcode :value="hash" :width="160" :color="color" :margin="9" />
      </div>
      <div class="text-sm">
        <div class="text-yellow-300" v-if="expiresIn > 0">
          <p>The code will expire in {{ expiresIn }} seconds</p>
        </div>
        <div v-else>
          <p class="text-red-400">Code Expired!</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import VueQrcode from 'vue-qrcode';
import colors from 'tailwindcss/colors';
import useHttp from '@/composeables/http';

const $http = useHttp();

//COLORS
const color = {
  dark: colors.slate['600'],
  light: colors.slate['900']
};

//CODE EXPIRATION
const expiresIn = ref(30);
const expireInterval = ref(null);
function startCountdown() {
  expireInterval.value = setInterval(() => {
    expiresIn.value--;
    if (expiresIn.value <= 0) {
      codeExpired.value = true;
      clearInterval(expireInterval.value);
    }
  }, 1000);
}
onMounted(() => {
  startCountdown();
});
onBeforeUnmount(() => {
  clearInterval(expireInterval.value);
});

//QR Code
const hash = ref('');
const codeExpired = ref(false);
const generateToken = async (refresh = true) => {
  const response = await $http.post('/data/chat_qr_code');
  hash.value = response.data;
  if (refresh) {
    codeExpired.value = false;
    expiresIn.value = 30;
    clearInterval(expireInterval.value);
    startCountdown();
  }
};
onMounted(() => {
  generateToken();
  //expire after 30 seconds
  setTimeout(() => {
    generateToken(false);
  }, 30000);
});
</script>
